const scrollElements = document.querySelectorAll(".js-scroll");

export const elementInView = (el, dividend = 1) => {
  const elementTop = el.getBoundingClientRect().top;

  return (
    elementTop <=
    (window.innerHeight || document.documentElement.clientHeight) / dividend
  );
};

export const elementOutofView = (el) => {
  const elementTop = el.getBoundingClientRect().top;

  return (
    elementTop > (window.innerHeight || document.documentElement.clientHeight)
  );
};

const displayScrollElement = (element) => {
  element.classList.add("scrolled");
};

const hideScrollElement = (element) => {
  element.classList.remove("scrolled");
};

export const handleScrollAnimation = () => {
  scrollElements.forEach((el) => {
    if (elementInView(el, 1.25)) {
      displayScrollElement(el);
    }
    // else if (elementOutofView(el)) {
    //   hideScrollElement(el)
    // }
  });
};

// Check for query url on news page and scroll to
const queryString = window.location.search;

if (queryString && document.getElementById("newsContainer")) {
  var top = document.getElementById("newsContainer").offsetTop;
  window.scrollTo(0, top);
}
