import { CountUp } from "countup.js";

import {
  openNav,
  closeNav,
  openSubnav,
  closeSubnav,
} from "./modules/navigation.js";
import * as swiper from "./modules/carousels.js";
import {
  elementInView,
  elementOutofView,
  handleScrollAnimation,
} from "./modules/scroll-animate.js";

import Player from "@vimeo/player";

// Vimeo Player
function initVimeo() {
  const iframe = $(".vimeo-player");
  const mute = $(".volume-toggle");
  const volumeOn = $(".volume-on");
  const volumeOff = $(".volume-off");

  const options = {
    autoplay: true,
    byline: false,
    controls: false,
    loop: true,
    muted: true,
    portrait: false,
    responsive: true,
    title: false,
  };

  const player = new Player(iframe, options);
  player.play();
  player.setVolume(0);

  $(mute).click(function () {
    player.getVolume().then(function (volume) {
      if (volume == 0) {
        player.setVolume(1);
        volumeOff.addClass("hidden");
        volumeOn.removeClass("hidden");
      } else {
        player.setVolume(0);
        volumeOn.addClass("hidden");
        volumeOff.removeClass("hidden");
      }
    });
  });
}

if ($(".vimeo-player").length) {
  initVimeo();
}

// Debounce scroll events
function debounce(func, wait) {
  let timeout;
  return function () {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      func.apply(this, arguments);
    }, wait);
  };
}

// Toggle navigation
$("#hamburger").click(function (e) {
  e.preventDefault();
  $(
    $("#main-navigation").hasClass("-translate-x-full") ? openNav() : closeNav()
  );
});

var timeout;

// Reset if browser is resized
$(window).resize(function () {
  // Prevent the navigation from animating during a resize event
  $("#main-navigation").removeClass("transition duration-300");
  clearTimeout(timeout);

  timeout = setTimeout(function () {
    $("#main-navigation").addClass("transition duration-300");
  }, 500);

  if ($(window).width() >= 1180) {
    closeNav();
    closeSubnav($(".subnav"));
  }
});

// Toggle subnavigation
$(".toggle-subnav").click(function () {
  if ($(window).width() < 1180) {
    var t = $(this).next(".subnav");

    closeSubnav($(".subnav").not(t));
    t.hasClass("hidden") ? openSubnav(t) : closeSubnav(t);
  }
});

// Close subnav when clicked outside
$(document).mouseup(function (e) {
  var container = $(".subnav").parent();

  if (!container.is(e.target) && container.has(e.target).length === 0) {
    closeSubnav($(".subnav"));
  }
});

// Detect user scroll and show/hide nav
var lastScrollTop = 0;
var delta = 20;

$(window).scroll(function () {
  var nowScrollTop = $(this).scrollTop();

  if (nowScrollTop > 75) {
    $("body > header").addClass("bg-blue-300/80");

    if (Math.abs(lastScrollTop - nowScrollTop) >= delta) {
      if (nowScrollTop > lastScrollTop) {
        // Scroll down
        $("body > header").addClass("-translate-y-full");
      } else {
        // Scroll up
        $("body > header").removeClass("-translate-y-full");
      }
      lastScrollTop = nowScrollTop;
    }
  } else {
    $("body > header").removeClass("bg-blue-300/80");
  }
});

// Scroll to top on 'skip to content' focus
$('a[href="#main"]').focus(function () {
  window.scrollTo({ top: 0, behavior: "smooth" });
});

$("body > header a").focus(function () {
  $("body > header").removeClass("-translate-y-full");
});

// Accordions
$(".accordion-toggle").click(function () {
  var body = $(this).next(".accordion-text");

  $(this).find("svg").toggleClass("hidden");

  if (body.length) {
    body.slideToggle();
  }
});

$(".accordion2-toggle").click(function () {
  const $current = $(this);
  const $others = $(".accordion2-toggle.open").not($current);

  if ($(window).width() >= 768) {
    $others.each(function () {
      toggleAccordion($(this));
    });
  }

  toggleAccordion($current);
});

function toggleAccordion($elem) {
  const $container = $elem.parent();
  const $icon = $elem.children("span").eq(0);
  const $body = $elem.next();

  $elem.toggleClass("open");
  $container.toggleClass("bg-slate-100 text-blue-300");
  $icon.toggleClass("bg-slate-300 rotate-180");

  $body.slideToggle();
}

// Countup
const counters = document.querySelectorAll(".countup");

counters.forEach((el) => {
  var decimal = el.dataset.points ?? 1;

  const options = {
    decimalPlaces: decimal,
    duration: 1.5,
    suffix: "%",
  };

  let counter = new CountUp(el, el.dataset.total, options);

  if (elementInView(el, 1.25)) {
    counter.start();
  }

  window.addEventListener("scroll", () => {
    if (elementInView(el, 1.25)) {
      counter.start();
    }
  });
});

// Play carousel video
const vids = document.querySelectorAll(".carouselVideo");

vids.forEach((vid) => {
  if (elementInView(vid, 1.25)) {
    vid.play();
  }

  window.addEventListener("scroll", () => {
    if (elementInView(vid, 1.25)) {
      vid.play();
    }
  });
});

// Tabs
$(".tab-toggle").click(function () {
  var siblings = $(".tab-toggle").not(this);

  // Toggle button
  siblings.addClass("opacity-50");
  $(this).toggleClass("opacity-50");

  // Toggle mobile image
  siblings.next(".tab-target").addClass("hidden");
  $(this).next(".tab-target").toggleClass("hidden");

  // Toggle desktop image
  $(".tab-target-p").addClass("hidden");
  $(".tab-target-p")
    .eq($(this).index() / 2)
    .removeClass("hidden");
});

// Animate on scroll
handleScrollAnimation();

window.addEventListener("scroll", () => {
  debounce(handleScrollAnimation(), 100);
});

// Lazy load Vimeo
$(".loadVimeo").click(function () {
  var src = $(this).data("src");
  var lightbox = $(this).parent().next(".lightbox");

  lightbox.removeClass("hidden");
  lightbox.find("iframe").attr("src", src);
});

$(".hideLightbox").click(function () {
  $(".lightbox").addClass("hidden");
  $(".lightbox iframe").removeAttr("src");
});
