import Swiper from "swiper/swiper-bundle";

const stories = new Swiper(".stories", {
  autoplay: {
    delay: 8000,
  },
  loop: true,
  pagination: {
    el: ".swiper-pagination",
    type: "bullets",
    bulletClass: "swiper-pagination-bullet !bg-current",
    clickable: true,
  },
  slidesPerView: 1,
  threshold: 2,
});

const carousel = new Swiper(".carousel", {
  navigation: {
    nextEl: ".button-next",
    prevEl: ".button-prev",
    disabledClass: "opacity-10",
  },
  slidesPerView: 1,
  threshold: 2,
  breakpoints: {
    768: {
      slidesPerView: $(".carousel").data("slides"),
      spaceBetween: 32,
    },
  },
  on: {
    slideChange: function () {
      if ($(".swiper-slide-active .carouselVideo").length) {
        var vid = $(".swiper-slide-active .carouselVideo")[0];
        vid.pause();
        vid.currentTime = 0;
      }
    },
    transitionEnd: function () {
      if ($(".swiper-slide-active .carouselVideo").length) {
        var vid = $(".swiper-slide-active .carouselVideo")[0];
        vid.play();
      }
    },
  },
});

const horizontal = new Swiper(".horizontal", {
  autoplay: {
    delay: 3000,
  },
  loop: true,
  slidesPerView: 2,
  spaceBetween: 16,
  threshold: 2,
  breakpoints: {
    768: {
      slidesPerView: 3,
      spaceBetween: 32,
    },
    1024: {
      slidesPerView: 4,
      spaceBetween: 64,
    },
  },
});

const supporters = new Swiper(".supporters", {
  centeredSlides: true,
  initialSlide: 1,
  spaceBetween: 32,
  navigation: {
    nextEl: ".button-next",
    prevEl: ".button-prev",
    disabledClass: "opacity-10",
  },
  slidesPerView: 1,
  threshold: 2,
  breakpoints: {
    1024: {
      slidesPerView: 2,
      spaceBetween: 32,
    },
  },
});

export { stories, carousel, horizontal, supporters };
