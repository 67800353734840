export function openNav() {
  $("#main-navigation").removeClass("-translate-x-full opacity-0");
  $(".bars").addClass("hidden");
  $(".xmark").removeClass("hidden");
}

export function closeNav() {
  $("#main-navigation").addClass("-translate-x-full opacity-0");
  $(".bars").removeClass("hidden");
  $(".xmark").addClass("hidden");
  closeSubnav($(".subnav"));
}

export function openSubnav(subnav) {
  subnav
    .parent()
    .addClass("bg-white nav:bg-transparent text-blue-300 nav:text-white");
  subnav.removeClass("hidden");
  subnav.parent().find(".down-arrow").addClass("rotate-180");
}

export function closeSubnav(subnav) {
  subnav
    .parent()
    .removeClass("bg-white nav:bg-transparent text-blue-300 nav:text-white");
  subnav.addClass("hidden");
  subnav.parent().find(".down-arrow").removeClass("rotate-180");
}
